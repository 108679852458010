import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Login from '@/components/Login'
import NotFound from '@/components/NotFound'

import globals from '@/globals'

// Layouts
import Layout2 from '@/layout/Layout2'
import LayoutAuth from '@/layout/LayoutAuth'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [{
    path: '/',
    component: Layout2,
    children: [{
      name: 'dashboard',
      path: '',
      component: () => import('@/components/Dashboard')
    }, {
      name: 'profile',
      path: '/profile',
      component: () => import('@/components/Profile')
    }, {
      name: 'account',
      path: '/account',
      component: () => import('@/components/Account')
    }, {
      name: 'form-insights',
      path: '/insights/form-engagement',
      component: () => import('@/components/insights/FormEngagementWrapper')
    }, {
      name: 'form-insights-for-form',
      path: '/insights/form-engagement/:id',
      meta: {redirectAfterSwitch: 'form-insights'},
      component: () => import('@/components/insights/FormEngagementWrapper')
    }, {
      name: 'field-metrics',
      path: '/insights/field-metrics',
      component: () => import('@/components/insights/FieldMetricsWrapper')
    }, {
      name: 'field-metrics-for-form',
      path: '/insights/field-metrics/:id',
      meta: {redirectAfterSwitch: 'field-metrics'},
      component: () => import('@/components/insights/FieldMetricsWrapper')
    }, {
      name: 'score',
      path: '/insights/score',
      component: () => import('@/components/insights/Score')
    }, {
      name: 'score-prefilled',
      path: '/insights/score/:url',
      meta: {redirectAfterSwitch: 'score'},
      component: () => import('@/components/insights/Score')
    }, {
      name: 'session-replays',
      path: '/insights/session-replays',
      component: () => import('@/components/insights/SessionReplay.vue')
    }, {
      name: 'session-replays-for-form',
      path: '/insights/session-replays/:id',
      meta: {redirectAfterSwitch: 'session-replays'},
      component: () => import('@/components/insights/SessionReplay.vue')
    }, {
      name: 'industry-benchmarks',
      path: '/insights/industry-benchmarks',
      component: () => import('@/components/insights/IndustryBenchmarksWrapper.vue')
    }, {
      name: 'industry-benchmarks-for-form',
      path: '/insights/industry-benchmarks/:id',
      meta: {redirectAfterSwitch: 'industry-benchmarks'},
      component: () => import('@/components/insights/IndustryBenchmarksWrapper.vue')
    }, {
      name: 'tags',
      path: '/setup/tags',
      component: () => import('@/components/setup/TagList')
    }, {
      name: 'tags-add',
      path: '/setup/tags/add/:type',
      component: () => import('@/components/setup/TagForm')
    }, {
      name: 'tags-edit',
      path: '/setup/tags/:id',
      meta: {redirectAfterSwitch: 'tags'},
      component: () => import('@/components/setup/TagForm')
    }, {
      name: 'forms',
      path: '/setup/forms',
      component: () => import('@/components/setup/FormList')
    }, {
      name: 'forms-add',
      path: '/setup/forms/add',
      component: () => import('@/components/setup/FormForm')
    }, {
      name: 'forms-edit',
      path: '/setup/forms/:id',
      meta: {redirectAfterSwitch: 'forms'},
      component: () => import('@/components/setup/FormForm')
    }, {
      name: 'st-forms',
      path: '/smart-tooltips/form-field-notifications',
      component: () => import('@/components/smart-tooltips/Campaigns')
    }, {
      name: 'st-forms-add',
      path: '/smart-tooltips/form-field-notifications/add',
      component: () => import('@/components/smart-tooltips/CampaignEditor')
    }, {
      name: 'st-forms-edit',
      path: '/smart-tooltips/form-field-notifications/:id',
      meta: {redirectAfterSwitch: 'st-forms'},
      component: () => import('@/components/smart-tooltips/CampaignEditor')
    }, {
      name: 'st-library',
      path: '/smart-tooltips/library',
      component: () => import('@/components/smart-tooltips/Library')
    }, {
      name: 'st-templates',
      path: '/smart-tooltips/templates',
      component: () => import('@/components/smart-tooltips/Templates')
    }, {
      name: 'st-templates-add',
      path: '/smart-tooltips/templates/add',
      component: () => import('@/components/smart-tooltips/TemplateEditor')
    }, {
      name: 'st-templates-edit',
      path: '/smart-tooltips/templates/:id',
      meta: {redirectAfterSwitch: 'st-templates'},
      component: () => import('@/components/smart-tooltips/TemplateEditor')
    }, {
      name: 'performance-alerts-checks',
      path: '/performance-alerts/checks',
      component: () => import('@/components/performance-alerts/Overview')
    }, {
      name: 'performance-alerts-notifications',
      path: '/performance-alerts/notifications',
      component: () => import('@/components/performance-alerts/Notifications')
    }, {
      name: 'notification-channels',
      path: '/performance-alerts/notification-channels',
      component: () => import('@/components/performance-alerts/NotificationChannels')
    }, {
      name: 'integrations-partners',
      path: '/integrations/partners',
      component: () => import('@/components/integrations/Partners')
    }, {
      name: 'websites',
      path: '/administration/websites',
      component: () => import('@/components/administration/WebsiteList')
    }, {
      name: 'websites-add',
      path: '/administration/websites/add',
      component: () => import('@/components/administration/WebsiteForm')
    }, {
      name: 'websites-add-prefilled',
      path: '/administration/websites/add/:website',
      component: () => import('@/components/administration/WebsiteForm')
    }, {
      name: 'websites-edit',
      path: '/administration/websites/:id',
      component: () => import('@/components/administration/WebsiteForm')
    }, {
      name: 'companies',
      path: '/administration/companies',
      component: () => import('@/components/administration/CompanyList')
    }, {
      name: 'companies-add',
      path: '/administration/companies/add',
      component: () => import('@/components/administration/CompanyForm')
    }, {
      name: 'companies-edit',
      path: '/administration/companies/:id',
      component: () => import('@/components/administration/CompanyForm')
    }, {
      name: 'users',
      path: '/administration/users',
      component: () => import('@/components/administration/UserList')
    }, {
      name: 'users-add',
      path: '/administration/users/add',
      component: () => import('@/components/administration/UserForm')
    }, {
      name: 'users-edit',
      path: '/administration/users/:id',
      component: () => import('@/components/administration/UserForm')
    }, {
      name: 'admin-tools',
      path: '/administration/admin-tools',
      component: () => import('@/components/administration/AdminTools.vue')
    }, {
      name: 'admin-tools-forms-bulk-management',
      path: '/administration/admin-tools/forms-bulk-management',
      component: () => import('@/components/administration/tools/BulkFormManagement.vue')
    }]
  }, {
    path: '/login',
    component: LayoutAuth,
    children: [{
      name: 'login',
      path: '',
      component: Login
    }, {
      path: ':email',
      component: Login
    }]
  }, {
    path: '/',
    component: LayoutAuth,
    children: [{
      name: 'signup',
      path: '/signup',
      component: () => import('@/components/Signup')
    }]
  }, {
    path: '/subscription/',
    component: LayoutAuth,
    children: [{
      name: 'subscription',
      path: '/subscription/welcome',
      component: () => import('@/components/SubscriptionProcessor')
    }]
  }, {
    path: '/password-reset/',
    component: LayoutAuth,
    children: [{
      name: 'password-reset',
      path: '',
      component: () => import('@/components/PasswordReset')
    }, {
      name: 'password-reset-email',
      path: ':email',
      component: () => import('@/components/PasswordReset')
    }, {
      name: 'password-reset-end',
      path: ':email/:token',
      component: () => import('@/components/PasswordResetEnd')
    }]
  }, {
    // 404 Not Found page
    path: '*',
    component: NotFound
  }]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)

  // Subscription expired
  if (router.app.$root && router.app.$root.me && router.app.$root.setupStatusStep === 1000) {
    if (!router.app.$root.hasRole('admin.internal')) {
      if (router.currentRoute.name !== 'account') {
        router.push({ name: 'account' })
        this.$plausible.trackEvent('trialEnded')
      }

      // Limit UI functions to the finance role
      router.app.$root.clearRoles()
    } else {
      console.warn('[admin.internal] Normally I would redirect to the account page as the subscription expired')
    }
  } else {
    router.app.$root.setupStatusStep = null
  }

  router.app.$api.conditionallyRefreshToken()

  // Remove debug info from the menu
  router.app.$root.clearDebugDataFromMenu()

  if (router.app?.$root?.client?.name) {
    router.app?.$plausible?.trackEvent('website', { props: router.app.$root.client.name })
  }
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  document.body.dispatchEvent(new CustomEvent('vueRouterBeforeEach', {
    detail: { to, from, next }
  }))

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
